import React from 'react'
import { Route, Routes } from 'react-router-dom'
import UploadDistributorCertificate from '../../private/actionDialogs/UploadDistributorCertificate'
import ModalRoute from '../../private/ModalRoute'
import GenerateNewLicenses from '../../private/actionDialogs/GenerateNewLicenses'
import ActivateCountingFunction from '../../private/actionDialogs/ActivateCountingFunction'
import ActivateSKFunction from '../../private/actionDialogs/ActivateSKFunction'

const DistributorRoutes = ({
  handleGenerateNewLicenses,
  handleUpdateCertificate,
  handleUpdateCountingFunction
}) => (
  <Routes>
    <Route
      path='*'
      element={
        <>
          <ModalRoute
            path='generate-new-licenses'
            component={GenerateNewLicenses}
            onSubmit={handleGenerateNewLicenses}
          />
          <ModalRoute
            path='update-certificate'
            component={UploadDistributorCertificate}
            onSubmit={handleUpdateCertificate}
          />
          <ModalRoute
            path='activate-counting'
            component={ActivateCountingFunction}
            onSubmit={handleUpdateCountingFunction}
          />
          <ModalRoute
            path='activate-sk'
            component={ActivateSKFunction}
            onSubmit={handleUpdateCountingFunction}
          />
        </>
      }
    />
  </Routes>
)

export default DistributorRoutes
