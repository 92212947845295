import { Accordion, AccordionDetails } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import AccordionSummaryWithActions from '../common/AccordionSummaryWithActions'
import EmployeeTable from '../employee/EmployeeTable'
import { useShopEmployees } from '../employee/hooks'
import { useShopLicenses } from '../license/hooks'
import LicenseTable from '../license/LicenseTable'
import { useShop, useShopMenuActions } from './hooks'
import { Storefront, Lock, LockOpen } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useBackOffice } from '../../private/hooks/useBackOffice'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingInline: theme.spacing(3),
    flexDirection: 'column'
  }
}))

const Shop = ({ onClick, expanded, id }) => {
  const classes = useStyles()
  const shop = useShop(id)
  const licenses = useShopLicenses(id)
  const employees = useShopEmployees(id)
  const handleOnClick = () => onClick(id)
  const { t } = useTranslation('oasisBackoffice')
  const [{ scans, schedules, oasisApiCalls }] = useBackOffice()
  const schedule = schedules.byId[shop.schedule]

  const menuActions = useShopMenuActions(shop)

  const shopScanCount = scans.byShopId[id]?.scans.length
  const shopOasisApiCalls = oasisApiCalls.byShopId[id]?.oasisApiCalls

  return (
    <Accordion expanded={expanded} onChange={handleOnClick}>
      <AccordionSummaryWithActions
        header={shop.name}
        actions={menuActions}
        scanCount={shopScanCount}
        shopSchedule={schedule?.name}
        oasisApiCalls={shopOasisApiCalls}
      >
        <Storefront fontSize='medium' color='disabled' />
        {shop.locked
          ? <Lock fontSize='medium' color='action' />
          : <LockOpen fontSize='medium' color='disabled' />}
      </AccordionSummaryWithActions>
      <AccordionDetails classes={classes}>
        {shop.locked && (
          <Typography variant='h5' align='center'>
            {t('shop.shopLocked')}
          </Typography>
        )}
        <Typography variant='h6'>{t('shop.headerEmployee')}</Typography>
        <EmployeeTable
          items={employees}
          employeeScans={scans.byShopId[id]?.scansByEmployeeId}
          employeeOasisApiCalls={oasisApiCalls.byShopId[id]?.oasisApiCallsByEmployeeId}
          shopId={id}
        />
        <Typography variant='h6'>{t('shop.headerLicenses')}</Typography>
        <LicenseTable items={licenses} />
      </AccordionDetails>
    </Accordion>
  )
}

export default Shop
