export const UserRoles = {
  Owner: 1,
  Distributor: 2,
  Admin: 3
}

export const OasisApiCallStatuses = {
  error: 0,
  success: 1
}

export const AvailableFeatures = {
  oasisApiCounting: 'OasisApiCounting',
  digitalSK: 'DigitalSK'
}

export const UserFeatures = {
  OasisApiCounting: 0x01,
  DigitalSK: 0x02
}
