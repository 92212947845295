import apiFetch, { getPathnameWithQuery } from './helpers'

const privateApi = ({ baseUrl, logger, session }) => {
  const transformResponse = ({ data }) => data
  const { get, post, patch, upload, deleteReq, uploadPut } = apiFetch({
    baseUrl,
    logger,
    session,
    transformResponse
  })

  return {
    assignEmployeesToShop: (id, items) =>
      post(`shops/${id}/assignEmployees`, { items }),
    assignLicensesToOwner: (id, items) =>
      post(`owners/${id}/assignLicenses`, { items }),
    assignLicensesToShop: (id, items) =>
      post(`shops/${id}/assignLicenses`, { items }),
    createEmployee: (id, data) => post(`owners/${id}/employees`, data),
    createOwner: (id, data) => post(`distributors/${id}/owners`, data),
    deleteShop: (id) => deleteReq(`shops/${id}`),
    createShop: (id, data) => post(`owners/${id}/shops`, data),
    editShopCredentials: (id, data) =>
      patch(`shops/${id}/editShopCredentials`, data),
    updateUserFeature: (id, data) =>
      patch(`users/${id}/features`, data),
    editShop: (id, data) => patch(`shops/${id}`, data),
    freeShopLicenses: (id, data) => post(`shops/${id}/freeLicenses`, data),
    fetchDistributors: () => get('distributors'),
    fetchDistributorEmployees: (distributorId) =>
      get(`distributors/${distributorId}/employees`),
    fetchDistributorLicenses: (distributorId) =>
      get(`distributors/${distributorId}/licenses`),
    fetchDistributorOwners: (distributorId) =>
      get(`distributors/${distributorId}/owners`),
    fetchDistributorShops: (distributorId) =>
      get(`distributors/${distributorId}/shops`),
    fetchDistributorScans: (distributorId, params) =>
      get(getPathnameWithQuery(`distributors/${distributorId}/scans`, params)),
    keepAlive: () => get('keepAlive'),
    setEmployeePassword: (id, data) =>
      post(`employees/${id}/setPassword`, data),
    activateEmployee: (id, data) => post(`employees/${id}/activate`, data),
    sendEmployeeActivationLink: (id) =>
      post(`employees/${id}/sendActivationLink`),
    uploadCertificate: (id, data) =>
      upload(`distributors/${id}/certificates`, data),
    updateCertificate: (id, data) =>
      uploadPut(`distributors/${id}/certificates`, data),
    generateNewLicenses: (id, data) => post(`licenses/${id}`, data),
    lockShop: (id, data) => post(`shops/${id}/lock`, data),
    configureSK: (id, data) => post(`shops/${id}/configureSK`, data),
    fetchDictionaryMetaData: () => get('meta-data'),
    fetchDistributorSchedules: (distributorId) =>
      get(`distributors/${distributorId}/schedules`),
    fetchDistributorOasisApiCalls: (distributorId, params) =>
      get(getPathnameWithQuery(`distributors/${distributorId}/oasisApiCalls`, params))
  }
}

export default privateApi
