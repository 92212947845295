import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useDistributor, useDistributorSelect } from '../distributor/hooks'
import useAuth from '../../hooks/useAuth'
import FormControl from '@material-ui/core/FormControl'
import { Button, MenuItem, Select } from '@material-ui/core'
import { CHANGE_DISTRIBUTOR } from '../../reducer/actions'
import { useBackOffice } from '../../private/hooks/useBackOffice'
import { useTranslation } from 'react-i18next'
import { UserFeatures, UserRoles } from '../../private/const'
import privateRoutes from '../../private/routes'
import useNavigateTo from '../../hooks/useNavigateTo'
import { isFeatureActivated } from '../../private/featureHelpers'

const useStyles = makeStyles((theme) => (
  {
    distributorNameBox: {
      position: 'fixed',
      top: theme.spacing(0.75),
      right: theme.spacing(4)
    },
    distributorName: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontSize: '0.875rem'
    },
    adminSection: {
      display: 'flex',
      gap: '26px'
    },
    button: {
      whiteSpace: 'nowrap',
      minWidth: 'max-content'
    }
  }))

const DistributorSection = () => {
  const classes = useStyles()
  const [, dispatch] = useBackOffice()
  const distributor = useDistributor()
  const { options, selected } = useDistributorSelect()
  const { user: { userRole } } = useAuth()
  const { t } = useTranslation('oasisBackoffice')
  const navigateTo = useNavigateTo()
  
  const countingActivationButtonLabel =
    (userRole === UserRoles.Admin && isFeatureActivated(distributor.users[0].features, UserFeatures.OasisApiCounting))
      ? t('distributor.deactivateAdminButtonLabel')
      : t('distributor.activateAdminButtonLabel')
      
  const skActivationButtonLabel =
    (userRole === UserRoles.Admin && isFeatureActivated(distributor.users[0].features, UserFeatures.DigitalSK))
      ? t('distributor.deactivateSKButtonLabel')
      : t('distributor.activateSKButtonLabel')

  const handleDistributorChange = (event) => {
    dispatch({
      type: CHANGE_DISTRIBUTOR, payload: event.target.value
    })
  }

  const handleAddLicenses = () => navigateTo(privateRoutes.generateNewLicenses, { id: distributor.id })
  const handleActivateCounting = () => navigateTo(privateRoutes.activateCountingFunction, { id: distributor.users[0].id })
  const handleActivateSK = () => navigateTo(privateRoutes.activateSKFunction, { id: distributor.users[0].id })

  return (
    <Box className={classes.distributorNameBox}>
      {userRole < UserRoles.Admin
        ? (
          <Typography className={classes.distributorName}>
            {distributor.name}
          </Typography>)
        : (
          <span className={classes.adminSection}>
            <Button
              className={classes.button}
              size='small'
              onClick={handleActivateCounting}
              color='primary'
              variant='outlined'
            >
              {countingActivationButtonLabel}
            </Button>
            <Button
              className={classes.button}
              size='small'
              onClick={handleActivateSK}
              color='primary'
              variant='outlined'
            >
              {skActivationButtonLabel}
            </Button>
            <Button
              className={classes.button}
              size='small'
              onClick={handleAddLicenses}
              color='primary'
              variant='outlined'
            >
              New license
            </Button>
            <FormControl fullWidth size='small'>
              <Select
                value={selected}
                label=''
                onChange={handleDistributorChange}
              >
                {options.map(({ id, name, missingCertificate }) =>
                  <MenuItem
                    key={id}
                    disabled={missingCertificate}
                    value={id}
                  >
                    {`${name}${missingCertificate ? t('distributor.missingCertificate') : ''}`}
                  </MenuItem>)}
              </Select>
            </FormControl>
          </span>)}
    </Box>
  )
}

export default DistributorSection
