import { sortBy } from 'lodash'
import { useMemo } from 'react'
import useNavigateTo from '../../hooks/useNavigateTo'
import { useBackOffice } from '../../private/hooks/useBackOffice'
import privateRoutes from '../../private/routes'
import { useAssignLicenses, useShopAssignableLicenses } from '../license/hooks'
import useAuth from '../../hooks/useAuth'
import { AvailableFeatures, UserFeatures, UserRoles } from '../../private/const'
import { isFeatureEnabled } from '../../helpers'

export const useShopMenuActions = (shop) => {
  const navigateTo = useNavigateTo()
  const licenses = useShopAssignableLicenses(shop.id)
  const assignLicenses = useAssignLicenses(licenses, () => navigateTo(privateRoutes.assignLicensesToShop, { id: shop.id }))
  const { user: { userRole, features } } = useAuth()

  return useMemo(
    () => {
      const distributorActions = [
        {
          label: 'assignEmployees',
          onClick: () => navigateTo(privateRoutes.assignEmployeesToShop, { id: shop.id })
        },
        assignLicenses,
        {
          label: 'deleteShop',
          onClick: () => navigateTo(privateRoutes.deleteShop, { id: shop.id })
        },
        {
          label: 'editShop',
          onClick: () => navigateTo(privateRoutes.editShop, { id: shop.id })
        },
        {
          label: 'editShopCredentials',
          onClick: () => navigateTo(privateRoutes.editShopCredentials, { id: shop.id })
        },
        {
          label: 'freeShopLicenses',
          onClick: () => navigateTo(privateRoutes.freeLicense, { id: shop.id })
        }
      ]

      const adminAction = {
        label: shop.locked ? 'unlockShop' : 'lockShop',
        onClick: () => navigateTo(privateRoutes.lockShop, { id: shop.id })
      }

      // Add SK action if user has DigitalSK feature
      if (isFeatureEnabled(features, [AvailableFeatures.digitalSK]) || userRole === UserRoles.Admin) {
        distributorActions.push({
          label: shop.skEnabled ? 'deactivateSK' : 'activateSK',
          onClick: () => navigateTo(privateRoutes.configureSK, { id: shop.id })
        })
      }

      userRole === UserRoles.Admin && distributorActions.push(adminAction)

      return distributorActions
    },
    [shop.id, shop.locked, shop.skEnabled, licenses, navigateTo, userRole, features]
  )
}

export const useShops = (ownerId) => {
  const [{ shops: { ids = [], byId } }] = useBackOffice()

  return useMemo(
    () => ids.filter((id) => byId[id].owner === ownerId),
    [ownerId, ids, byId]
  )
}

export const useSortedShops = (ownerId) => {
  const shopIds = useShops(ownerId)
  const [{ shops: { byId } }] = useBackOffice()

  return useMemo(
    () => sortBy(shopIds, (id) => byId[id].name.toLocaleLowerCase()),
    [ownerId, shopIds, byId]
  )
}

export const useShop = (id) => {
  const [{ shops: { byId } }] = useBackOffice()

  return byId[id]
}

export const useCreateShop = (id) => {
  const navigateTo = useNavigateTo()
  return () => navigateTo(privateRoutes.createShop, { id })
}

export const useCreateOwner = (id) => {
  const navigateTo = useNavigateTo()
  return () => navigateTo(privateRoutes.createOwner, { id })
}
